<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  @successPost="$router.push('/masters/users/admin-tu-prodi')"
                  routeBack="/masters/users/admin-tu-prodi"
                  roleId="6"
                  :form="form"
                  routePost="api/users"
                  purpose="add"
                  secondPurpose="special-user"
                  messageSuccess="Berhasil menambah admin tata usaha prodi"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/users/Form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        address: "",
        email: "",
        email2: "",
        name: "",
        nickname: "",
        phone: "",
        photo: null,
        description: "",
        role_id: "",
        password: "",
        major_id: "",
        registration_number: "",
        lecturer_code: "",
        nidn: "",
        birt_date: null,
        birt_place: ""
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Admin TU Prodi", route: "/masters/users/admin-tu-prodi" },
      { title: "Tambah Admin TU Prodi" },
    ]);
  },
};
</script>